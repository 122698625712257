import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class AttendanceRecords {
  getAttendanceRecordsReport(
    employer_id,
    employer_organisation_id,
    punch_date_begin,
    punch_date_end,
    page,
    page_num
  ) {
    return axios.get(
      `/api/admin/attendance-records/records/list`,
      {
        params: {
          employer_id: employer_id,
          employer_organisation_id: employer_organisation_id,
          punch_date_begin: punch_date_begin,
          punch_date_end: punch_date_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getAttendanceRecordsInfo(id) {
    return axios.get(
      `/api/admin/attendance-records/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getAttendanceRecordsList(
    id,
    employer_id,
    punch_date_begin,
    punch_date_end,
    shift_id,
    make_up,
    employer_name,
    employer_organisation_id,
    employer_department_ids,
    page,
    page_num
  ) {
    return axios.get(
      `/api/admin/attendance-records/list`,
      {
        params: {
          id: id,
          employer_id: employer_id,
          punch_date_begin: punch_date_begin,
          punch_date_end: punch_date_end,
          shift_id: shift_id,
          make_up: make_up,
          employer_name: employer_name,
          employer_organisation_id: employer_organisation_id,
          employer_department_ids: employer_department_ids,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchAttendanceRecords(datas) {
    return axios.post(
      `/api/admin/attendance-records/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addAttendanceRecordsNormal(employer_id, time_diff) {
    return axios.post(
      `/api/admin/attendance-records/normal`,
      {
        employer_id: employer_id,
        time_diff: time_diff,
      },
      config
    );
  }
  addAttendanceRecordsNight(employer_id, time_diff) {
    return axios.post(
      `/api/admin/attendance-records/night`,
      {
        employer_id: employer_id,
        time_diff: time_diff,
      },
      config
    );
  }

  addAttendanceRecords(employer_id, shift_id, make_up, time_diff) {
    return axios.post(
      `/api/admin/attendance-records/`,
      {
        employer_id: employer_id,
        shift_id: shift_id,
        make_up: make_up,
        time_diff: time_diff,
      },
      config
    );
  }
  editAttendanceRecords(id, employer_id, shift_id, make_up, remark) {
    return axios.put(
      `/api/admin/attendance-records/`,
      {
        id: id,
        employer_id: employer_id,
        shift_id: shift_id,
        make_up: make_up,
        remark: remark,
      },
      config
    );
  }
  deleteAttendanceRecords(id) {
    return axios.delete(
      `/api/admin/attendance-records/`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
